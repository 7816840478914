<template>
  <div class="maintenance-main">
    <div class="info">
      <el-form :inline="true" :model="dataForm" size="small" label-width="100px">
        <div class="row">
          <div class="col">
            <el-form-item label="车牌号" prop="vehiclePlate">
<!--              <el-select class="item-choose" v-model="dataForm.vehiclePlate" :disabled="isView" filterable clearable-->
<!--                         placeholder="请选择">-->
<!--                <el-option-->
<!--                  v-for="item in vehicleList"-->
<!--                  :key="item.vehiclePlate"-->
<!--                  :label="item.vehiclePlate"-->
<!--                  :value="item.vehiclePlate"-->
<!--                ></el-option>-->
<!--              </el-select>-->
              <el-cascader
                :disabled="isView"
                v-model="dataForm.vehiclePlate"
                :options="vehicleList"
                ref="cascaderTake"
                style="width: 100%"
                clearable
                filterable
                change-on-select
                :props="{ checkStrictly: false, value: 'vehiclePlate', label: 'vehiclePlate' }"
                placeholder="请选择"></el-cascader>
            </el-form-item>
          </div>
          <div class="col">
            <el-form-item label="送修时间" prop="repairTime">
              <el-date-picker v-model="dataForm.repairTime"
                              :disabled="isView"
                              type="datetime"
                              default-time="12:00:00"
                              format="yyyy-MM-dd HH:mm"
                              value-format="yyyy-MM-dd HH:mm"
                              placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="col">
            <el-form-item label="计划结束时间" prop="planSuccessTime">
              <el-date-picker v-model="dataForm.planSuccessTime"
                              :disabled="isView"
                              type="datetime"
                              default-time="12:00:00"
                              format="yyyy-MM-dd HH:mm"
                              value-format="yyyy-MM-dd HH:mm"
                              placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col">
<!--            <el-form-item label="维修厂" prop="repairFactory">-->
<!--              <el-input v-model="dataForm.repairFactory" placeholder="维修厂" :disabled="isView"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="维修厂" prop="repairFactory">
<!--              <el-select class="item-choose" v-model="dataForm.repairFactory"-->
<!--                         style="width: 100%;" filterable clearable placeholder="请选择">-->
<!--                <el-option-->
<!--                  v-for="item in dictTypeMap.repair_factory"-->
<!--                  :key="item.id"-->
<!--                  :label="item.name"-->
<!--                  :value="item.id"-->
<!--                  :disabled="isView"-->
<!--                ></el-option>-->
<!--              </el-select>-->

              <el-autocomplete class="inline-input" v-model="dataForm.repairFactory"
                               :fetch-suggestions="(str,cb)=>queryNameSearch(str,cb,this.repairFactory)"
                               style="width:100%"
                               clearable
                               placeholder="点击输入框弹出可选项"></el-autocomplete>
            </el-form-item>
          </div>
          <div class="col">
            <el-form-item label="维修类型" prop="repairType">
<!--              <el-select class="item-choose" v-model="dataForm.repairType"-->
<!--                         style="width: 100%;" filterable clearable placeholder="请选择">-->
<!--                <el-option-->
<!--                  v-for="item in dictTypeMap.repair_type"-->
<!--                  :key="item.id"-->
<!--                  :label="item.name"-->
<!--                  :value="item.id"-->
<!--                  :disabled="isView"-->
<!--                ></el-option>-->
<!--              </el-select>-->

              <el-autocomplete class="inline-input" v-model="dataForm.repairType"
                               :fetch-suggestions="(str,cb)=>queryNameSearch(str,cb,this.repairType)"
                               style="width:100%"
                               clearable
                               placeholder="点击输入框弹出可选项"></el-autocomplete>
            </el-form-item>
          </div>
          <div class="col">
<!--            <el-form-item label="维修项目" prop="repairProject">-->
<!--              <el-input v-model="dataForm.repairProject" placeholder="维修项目" :disabled="isView"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="维修项目" prop="repairProject">
<!--              <el-select class="item-choose" v-model="dataForm.repairProject"-->
<!--                         style="width: 100%;" filterable clearable placeholder="请选择">-->
<!--                <el-option-->
<!--                  v-for="item in dictTypeMap.repair_item"-->
<!--                  :key="item.id"-->
<!--                  :label="item.name"-->
<!--                  :value="item.id"-->
<!--                  :disabled="isView"-->
<!--                ></el-option>-->
<!--              </el-select>-->

              <el-autocomplete class="inline-input" v-model="dataForm.repairProject"
                               :fetch-suggestions="(str,cb)=>queryNameSearch(str,cb,this.repairProject)"
                               style="width:100%"
                               clearable
                               placeholder="点击输入框弹出可选项"></el-autocomplete>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <el-form-item label="送修人" prop="sendRepairUser">
              <el-input v-model="dataForm.sendRepairUser" placeholder="送修人" :disabled="isView"></el-input>
            </el-form-item>
          </div>
          <div class="col">
            <el-form-item label="维修金额(元)" prop="repairMoney">
              <el-input v-model="dataForm.repairMoney" placeholder="维修金额(元)" :disabled="isView"></el-input>
            </el-form-item>
          </div>
          <div class="col">
            <el-form-item label="维修单号" prop="repairNo">
              <el-input v-model="dataForm.repairNo" placeholder="维修单号" :disabled="isView"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col max-col">
            <el-form-item label="维修原因" prop="repairReason" style="width:100%">
              <el-input v-model="dataForm.repairReason" placeholder="维修原因" type="textarea" style="width:100%"
                        :disabled="isView"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col  max-col">
            <el-form-item label="附件">
              <al-upload :fileData="dataForm.enclosureInfoList" :fileUrl="$http.adornUrl('/upload/img')"
                         :isView="isView" :format="'img'" :type="'IdCard'" :limit="2" :businessType="8020"
                         @getFileData="getFileData"></al-upload>
            </el-form-item>
          </div>
        </div>
        <!--        <div class="row">-->
        <!--          <div class="col  max-col">-->
        <!--            <el-form-item label="备注" prop="remark">-->
        <!--              <el-input :rows="1" :disabled="isView" v-model="dataForm.remark" placeholder="备注"></el-input>-->
        <!--            </el-form-item>-->
        <!--          </div>-->
        <!--        </div>-->
        <el-row>
          <el-col :span="24">
            <div style="margin-top:8px;margin-bottom:8px;text-align: right">
              <el-button style="margin-left: 20px;" round type="primary" size="mini" @click="addTemplateRow()"
                         v-if="todo != 'view'">添加
              </el-button>
            </div>
            <div class="infoBox infoBox2">
              <el-table :data="dataForm.templateList" border size="mini"
                        :header-cell-style="{background:'#eef1f6',color:'#606266'}" style="width: 100%">
                <el-table-column type="index" label="序号" align="center" width="50">
                </el-table-column>
                <el-table-column prop="itemName" label="附加项目" align="center" width="120">
                  <template slot-scope="scope">
                    <el-input v-model="dataForm.templateList[scope.$index].itemName" :disabled="isView"
                              placeholder="附加项目"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="repairTime" align="center" label="送修日期">
                  <template slot-scope="scope">
                    <el-date-picker v-model="dataForm.templateList[scope.$index].repairTime" type="date"
                                    value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="isView">
                    </el-date-picker>
                  </template>
                </el-table-column>
                <el-table-column prop="partName" align="center" label="配件名称">
                  <template slot-scope="scope">
                    <el-input v-model="dataForm.templateList[scope.$index].partName" :disabled="isView"
                              placeholder="配件名称"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="unit" align="center" label="单位">
                  <template slot-scope="scope">
                    <el-input v-model="dataForm.templateList[scope.$index].unit" :disabled="isView"
                              placeholder="单位"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="num" align="center" label="数量">
                  <template slot-scope="scope">
                    <el-input v-model="dataForm.templateList[scope.$index].num"
                              @input="countMoney(scope.$index)"
                              :disabled="isView"
                              placeholder="数量"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="price" align="center" label="单价（元）">
                  <template slot-scope="scope">
                    <el-input v-model="dataForm.templateList[scope.$index].price"
                              @input="countMoney(scope.$index)"
                              :disabled="isView"
                              placeholder="单价（元）"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="money" align="center" label="金额（元）">
                  <template slot-scope="scope">
                    <el-input v-model="dataForm.templateList[scope.$index].money" :disabled="isView"
                              placeholder="金额（元）"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="80" v-if="todo !='view'">
                  <template slot-scope="scope">
                    <el-button type="text" @click="deleteRow(scope.$index)" size="small">
                      移除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div class="footer">
            <el-button type="primary" @click="submit" v-if="!isView">保存</el-button>
            <el-button type="danger" @click="gotoBack">关闭</el-button>
          </div>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dataForm: {
        repairMoney: null,
        enclosureInfoList: [],
        templateList: []
      },
      todo: '',
      row: '',
      repairFactory: 'repair_factory',
      repairType: 'repair_type',
      repairProject: 'repair_item',
      vehicleList: []
    }
  },
  activated () {
    this.getVehicle()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap },
      set (val) { this.$store.commit('common/updateDictMapByKey', val) }
    },
    isView () {
      return this.todo === 'view'
    }
  },
  mounted () {
    if (this.$route.params.todo) {
      this.todo = this.$route.params.todo
    }
    if (this.$route.params.row) {
      this.row = this.$route.params.row
      this.getDetail()
    }
  },
  methods: {
    // 获取基础数据字典信息
    getDictInfo () {
      this.$http({
        url: this.$http.adornUrl('/dict/findDictAll'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        let types = {}
        types[this.repairType] = data[this.repairType]
        this.dictTypeMap = types
        let projects = {}
        projects[this.repairProject] = data[this.repairProject]
        this.dictTypeMap = projects
      })
    },
    countMoney (index) {
      let templateList = this.dataForm.templateList
      let num = templateList[index].num
      let price = templateList[index].price
      if (num && price) {
        templateList[index].money = num * price
        let total = 0
        for (let i = 0; i < templateList.length; i++) {
          total += templateList[i].money
        }
        this.dataForm.repairMoney = total
      }
    },
    getDetail () {
      this.$http({
        url: this.$http.adornUrl('/vehicleRepair/getDetail/' + this.row.id),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.dataForm = {
          id: data.id,
          repairNo: data.repairNo,
          repairTime: data.repairTime,
          repairType: data.repairType,
          repairProject: data.repairProject,
          planSuccessTime: data.planSuccessTime,
          repairFactory: data.repairFactory,
          sendRepairUser: data.sendRepairUser,
          repairMoney: data.repairMoney,
          repairReason: data.repairReason,
          enclosureInfoList: data.enclosureInfoList,
          templateList: data.templateList
        }
        if (data.vehiclePlate) {
          if (data.vehiclePlate.indexOf('/') > -1) {
            this.dataForm.vehiclePlate = data.vehiclePlate.split('/')
          } else {
            this.dataForm.vehiclePlate = data.vehiclePlate
          }
        }
      })
    },
    getVehicle () {
      this.$http({
        url: this.$http.adornUrl('/vehicleInfo/findTreeList'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.vehicleList = data
      })
    },
    getDataList () {
    },
    getFileData (data) {
      this.dataForm.enclosureInfoList = data.fileList
    },
    submit () {
      // this.$refs['dataForm'].validate((valid) => {
      //   if (valid) {
      let methodName = !this.dataForm.id ? 'add' : 'update'
      let method = !this.dataForm.id ? 'put' : 'post'
      let formData = JSON.parse(JSON.stringify(this.dataForm))
      if (Array.isArray(formData.vehiclePlate)) {
        formData.vehiclePlate = formData.vehiclePlate[0] + '/' + formData.vehiclePlate[1]
      }
      this.$http({
        url: this.$http.adornUrl(`/vehicleRepair/` + methodName),
        method: method,
        data: this.$http.adornData(formData)
      }).then(({ data }) => {
        if (data) {
          this.getDictInfo()
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.gotoBack()
            }
          })
        } else {
          this.$message.error('操作失败')
        }
      })
      // }
      // })
    },
    gotoBack () {
      let val = this.$store.state.common.mainTabs.filter(item => item.name !== this.$route.name)
      this.$store.commit('common/updateMainTabs', val)
      this.$router.push({ name: 'certificates-vehicleMaintenance' })
    },
    queryNameSearch (queryString, cb, keyName) {
      let values = []
      let typeMapElement = this.dictTypeMap[keyName]
      for (let key in typeMapElement) {
        values.push({
          value: typeMapElement[key].name
        })
      }
      let results = queryString ? values.filter(this.createFilter(queryString, 'value')) : values
      if (!results.length) {
        results = [{
          value: ''
        }]
      }
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString, name) {
      return (restaurant) => {
        if (restaurant[name]) {
          return (restaurant[name].indexOf(queryString) === 0)
        }
        return false
      }
    },
    addTemplateRow () {
      this.dataForm.templateList.push({
        dateRange: null
      })
    },
    deleteRow (index) {
      this.$confirm(`删除后无法恢复,确定删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 点击确定执行函数
        this.dataForm.templateList.splice(index, 1)
      }).catch(() => {
        // 点击取消执行函数
      })
    }
  }
}
</script>

<style lang="less">
.maintenance-main {
  .row {
    display: flex;
    .el-form-item {
      margin-bottom: 10px;
    }
    .col {
      display: inline-block;
      flex: 1;
    }
    .el-upload {
        float: left;
      }
      .el-upload-list {
        display: inline-block;
        float: left;
        width: 80%;
        .el-upload-list__item {
            height: auto !important;
            margin: 0px !important;
            margin-left: 10px !important;
            float: left;
            width: auto !important;
        }
      }
    .max-col {
      .el-form-item {
        width: 100%;
        .el-input {
          width: 100%;
        }
      }
      .el-form-item__content {
        width: 88%;
      }
    }
  }
  .el-input {
    width: 120%;
  }

  .el-select .el-input {
    width: 112%;
  }
  .el-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .footer {
    text-align: right;
    margin-top: 10px;
  }
  .content {
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__content {
      max-height: 67vh;
      overflow-y: auto;
      padding-top: 10px;
    }
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: 86%;
    }
    .el-upload.el-upload--picture-card {
      width: 70px;
      height: 70px;
      line-height: 70px;
      margin:0
    }
    .el-upload-list__item {
      width: 70px !important;
      height: 70px !important;
      margin: 0px 8px 0 0 !important;
    }
    .el-tabs__content {
      max-height: 67vh;
      overflow-y: auto;
      padding-top: 10px;
    }
  }
  .el-form-item {
    margin-bottom: 8px
  }
  .infoBox {
    .el-input {
      width: 100%
    }
  }
}
</style>
